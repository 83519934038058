.Footer {
    background: #ccdee4;
    width: 100%;
    height: 8em;
    text-align: center;
}

h4 {
    justify-content: center;
    margin: 28px;
}