.PostListCard {
  position: relative;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  width: auto;
  margin: 0px 24px 24px 24px;
  background-color: white;
}

.problem {
}

.solution {
}

.category {
}

.date {
}
