.PostInputForm {
  margin: auto;
  width: 48em;
  padding: 16px;
  position: relative;
}

.text-input {
  width: 48em;
}

.input-form {
  position: relative;
  margin: auto;
  display: table;
  padding: 16px;
}

h4 {
  margin: 8px;
}

.formControl {
  width: 12em;
  min-width: auto;
}

.submitButton {
  margin-top: 16px;
}